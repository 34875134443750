.signUpContainer {
    max-width: 400px;
    border-radius: 5px;
}
.userDataContainer {
    padding: 20px;
}
.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signupButton {
    width: 100%;

    display: flex;
    justify-content: flex-end;
}

.signupButton Button {
    margin-top: 10px;
    margin-left: 10px;
}

.error {
    border-color: rgb(252, 103, 103);
    border: 0.5px;
}