.pageContainer {
    height: 100%;
    width: 100%;

    border-radius: 5px;
}

.panelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.additionalContainer {
    display: flex;
    margin-top: 5px;
    justify-content: flex-end;
    align-items: center;
}
.additionalContainer .resultContainer {
    display: flex;
    flex-grow: 1;
    margin-left: 20px;
    justify-content: flex-start;
}

.searchContainer {
    width: 250px;

    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: 5px;
}

.printButtonContainer {
    width: 350px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.pageContentContainer {
    display: flex;
    height: 90%;
    flex-direction: column;
}
.tableContainer {
    margin-top: 1px;
    margin-bottom: 10px;
    flex-grow: 1;
    border-radius: 5px;
}

.filterOptionContainer {
    display: flex;
}

.filterOptionsOne {
    flex-grow: 1;
}

.filterOptionsTwo {
    width: 300px;
    margin-top: 5px;
    padding-left: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.filterOptionButtonContainer {
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.filterOptionButtonContainer Button {
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 10px;
}

.drawerBodyContainer {
    width: 100%;
    height: 100%;
    padding: 10px;
}

/* .bodyColLeft {
}
.bodyColRight {
} */
.rs-panel-heading-toggle {
    display: none;
}

.additionalButtonGroup {
    padding: 5px;
}

.dropdownContainer {
    margin-top: 10px;
    width: 100%;
    display: flex;
}

.dropdownContainer .inputContainer {
    max-width: 600px;
    padding: 0;
    display: block;
}

.dropdownContainer .filterButtonContainer {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.dropdownContainer .filterButtonContainer Button {
    margin: 2px;
}