.profileContainer {
    height: 100%;
    width: 70%;
}
@media only screen and (max-width: 767px) {
    .profileContainer {
      width: 100%;
    }
}
/* .profileContentContainer {
    margin-top: 10px;
}
.buttonGroup {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.profileUserDetails{
    width: 100%;
    max-width: 340px;

    padding: 30px;
    padding-top: 1px;
    border-radius: 5px;
/* } */
.profileMsQuestion {
    width: 100%;
    margin-right: 10%;
    display: flex;
    align-items: center;
}
.profileMsQuestion p{
    flex-grow: 1;
    margin: 0;
}
.profileMsQuestion div {
    display: flex;
    justify-content: flex-end;
}
/* 


.profileContactContainer {
    width: 100%;
    display: flex;


}  */

.profileUserContainer {
    height: 100%;
    width: 100%;

    padding: 10px;
    border-radius: 5px;
}
.profileUserData {
    padding: 10px;
}

.profilePreferenceContainer {
    padding: 10px;
}
.profileMonthlyQuestion {
    display: flex;
    align-items: center;
}

.profileAdditionalContainer {
    padding: 10px;
}


.profileButtonGroup {
    display: flex;

    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.profileButtonGroup Button {
    margin-left: 10px;
}