.addAdminUserContainer {
    padding: 20px;
    border-radius: 5px;
    width: 100%;
}
.addAdminUserHeader {
    display: flex;
    align-items: center;
}
.addAdminUserHeader h5 {
    vertical-align: middle;
    margin-bottom: 0;
    margin-left: 10px;
}
.inputClass {
    margin-bottom: 15px;
}

.buttonContainer {
    margin-top: 25px;
    
}