.forgotContainer {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.inputContainer {
    height: 500px;
    max-width: 400px;
    width: 400px;

    border-radius: 5px;
}

.inputContainer p {
    font-size: small;
    margin-top: 20px;
    text-align: center;
}

.inputButtonContainer {
    width: 90%;
}