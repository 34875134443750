.notFoundContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notFoundContentContainer {
    height: 400px;

    width: 350px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.notFoundImage {
    height: 60%;
    width: 85%;

    margin-top: 20px;
    border-radius: 5px;
    background-position: center;
    background-color: rgba(128, 128, 128, 0.462);
}

.notFoundImage .notFoundImageHeader h1 {
    text-align: center;
    color: white;

    font-weight: bolder;
    font-size: 50px;
}
.notFoundImageHeader {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.infoContentContainer {
    width: 80%;
}

.infoContentContainer p{
    padding: 10px;
    padding-bottom: 0;

    text-align: center;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.infoContentContainer Button {
    font-weight: bold;
    text-decoration: underline;
}