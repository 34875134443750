.loginContainer {
    max-width: 500px;
    min-width: 400px;

    height: 400px;

    display: flex;
    justify-content: center;
    border-radius: 5px;
}

.loginContentContainer {
    width: 90%;
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inputContainer {
    flex: 1 0 75%;
    padding: 20px;
    padding-top: 20px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginButton {
    margin-top: 40px;
    width: 80%;
}
.loginButton Button {
    justify-content: center;
    width: 100%;
}

.buttonGroupContainer Button:hover {
    background-color: transparent !important;
}

.invalidInput {
    border-color: red !important;
}