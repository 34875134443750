/* .adminContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.extendButton {
    justify-content: flex-end;
    align-items: center;
    margin-left: 16px;
    height: 100%;
}
.extendButton Button {
    height: 100%;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.filterContainer {
    height: 55px;
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
}
.inputContainer {
    display: flex;
    align-items: center;
}

.contentContainer {
    display: flex;
    border-radius: 5px;
    
    height: 100%;
    width: calc(100% - 20px);

    align-items: flex-start;
}

.dataContainer {
    flex-grow: 1;
    min-height: 200px;
    border-radius: 5px;
    margin-right: 10px;
}
.dataContainer > div {
    display: flex;
    background-color: red;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.controlContainer {
    border-radius: 5px;
} */

/* .adminUserContainer {
    height: 100%;
    width: 100%;
}

.filterContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.inputContainer {
    display: flex;

    align-items: center;
    justify-content: flex-end;
    flex: 1;
} */
.filterPosition {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}
.filterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.filterOptions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.filterOptions p {
    margin: 0;
}

.adminContainer {
    flex: 1;
    display: flex;
    height: calc(100% - 60px);
}

.leftContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    height: 100%;

    margin-right: 10px;
    border-radius: 5px;
}
.emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
}
.currentUser {
    border: 1px;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 5px;
    border-color: #59afff;
    border-style: solid;
}
.highlight {
    color: red;
    text-decoration: underline;
    font-weight: bold;
    margin-left: 10px;
}